import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, ActivatedRoute } from '@angular/router';
import { LocalizationService } from './localization/service/localization.service';
import { SubSink } from './localization/utils';
import { Title } from '@angular/platform-browser';
import { EnvironmentProviderService } from './helpers/environment-provider.service';
import { AuthService } from './security/service/auth.service';
import { fromEvent } from 'rxjs';
import { DOCUMENT } from '@angular/common';

declare var $: any;
type SomeFunc = (value?: any) => void;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    currentRout = './';
    protected readonly subSink: SubSink = new SubSink();
    private idToScroll: string;
    currentLogoWhiteURL: string;
    currentLogoBlackURL: string;
    brandLogoCSSClass: string;
    messageDialogTitle: string;
    infoMessage: string;
    deletionDialogTitle: string;
    profileDeletedSuccessMessage: string;
    profileDeletedErrorMessage: string;
    sessionExpiredDialogTitle: string;
    sessionExpiredDialogMessage: string;
    getCardsErrorDialogTitle: string;
    getCardsErrorDialogMessage: string;
    getTpPendingDialogTitle: string;
    onOkFunc: SomeFunc;

    constructor(
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private readonly router: Router,
        private readonly localizationService: LocalizationService,
        private readonly titleService: Title,
        private readonly environmentProviderService: EnvironmentProviderService,
        private readonly authService: AuthService,
        private readonly route: ActivatedRoute,
    ) {
        router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                this.currentRout = event.url;
            }
        });
        this.subSink.sink = this.localizationService.languageChanged.subscribe(() => {
            this.titleService.setTitle(this.localizationService.getMessage('app.title'));
            this.currentLogoWhiteURL = this.localizationService.getMessage('app.currentLogoWhiteURL');
            this.currentLogoBlackURL = this.localizationService.getMessage('app.currentLogoBlackURL');
            this.brandLogoCSSClass = this.localizationService.getMessage('app.brandLogoCSSClass');
            this.deletionDialogTitle = this.localizationService.getMessage('app.confirmDeleteUserTitle');
            this.profileDeletedSuccessMessage = this.localizationService.getMessage('app.profileDeletedSuccessMessage');
            this.profileDeletedErrorMessage = this.localizationService.getMessage('app.profileDeletedErrorMessage');
            this.sessionExpiredDialogTitle = this.localizationService.getMessage('app.sessionExpiredDialogTitle');
            this.sessionExpiredDialogMessage = this.localizationService.getMessage('app.sessionExpiredDialogMessage');
            this.getCardsErrorDialogTitle = this.localizationService.getMessage('app.getCardsErrorDialogTitle');
            this.getCardsErrorDialogMessage = this.localizationService.getMessage('badRequest');
            this.getTpPendingDialogTitle = this.localizationService.getMessage('service.unavailable');
        });
        environmentProviderService.fillEnvSettings();
        AppComponent.registerEvent('shown.bs.modal');
        AppComponent.registerEvent('show.bs.modal');
    }

    private static registerEvent(event) {
        const script = document.createElement('script');
        script.innerHTML = 'eventRelay(\'' + event + '\');';
        document.body.appendChild(script);
    }

    public changeCurrentLanguage(): void {
        let switchToLang: string;
        this.localizationService.currentLanguage === 'en' ? (switchToLang = 'ru') : (switchToLang = 'en');
        this.localizationService.setLocale(switchToLang);
        localStorage.setItem('locale', switchToLang);
    }

    public openProfileModal() {
        $('#mobileMenuModal').modal('hide');
        $('#profileModal').modal('show');
    }

    ngOnDestroy(): void {
        this.subSink.unsubscribe();
    }

    logout(): void {
        this.authService.logout();
        $('#mobileMenuModal').modal('hide');
    }

    closeModal(): SomeFunc {
        return () => {
            $('#mobileMenuModal').modal('hide');
        };
    }

    ngOnInit(): void {
        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--vh', `${window.innerHeight}px`);
        };
        window.addEventListener('resize', appHeight);
        appHeight();
        fromEvent(window, 'orientationchange').subscribe(() => window.location.reload());
        fromEvent(document, 'shown.bs.modal').subscribe(() => {
            const el: HTMLElement = document.getElementById(this.idToScroll || 'firstEvent');
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
                this.idToScroll = '';
            }
        });
        this.authService.dataStream$().subscribe((value) => {
            switch (value) {
                case 0: {
                    this.showSessionExpiredMessage();
                    this.renderer.removeClass(this.document.body, 'bg-blur');
                    break;
                }
                case 1: {
                    this.showGetCardsErrorMessage();
                    break;
                }
                case 2: {
                    this.router.navigate(['/main-view'])
                        .then(() => this.showTpPendingMessage());
                    break;
                }
                case 3: {
                    this.showExtraMessageAfterLogin();
                    break;
                }
                default:
                    break;
            }
        });
    }

    scroll(id: string): void {
        if (id === 'secondQuestionHref') {
            $('#secondQuestion').collapse('show');
        }
        this.idToScroll = id;
    }

    public deleteUserProfile() {
        this.messageDialogTitle = this.deletionDialogTitle;
        this.subSink.sink = this.authService.deleteUserProfile().subscribe(
            () => {
                this.infoMessage = this.profileDeletedSuccessMessage;
                this.onOkFunc = this.logoutWrapper();
                $('#messageDialog').modal('show');
            },
            () => {
                $('#messageDialog').modal('show');
                this.infoMessage = this.profileDeletedErrorMessage;
            },
        );
    }

    hideConfirmToDeleteUserProfileDialog() {
        $('#confirmDeleteUserDialog').modal('hide');
    }

    onOk() {
        this.onOkFunc();
    }

    public showSessionExpiredMessage() {
        this.logout();
        $('.modal').modal('hide');
        this.messageDialogTitle = this.sessionExpiredDialogTitle;
        this.infoMessage = this.sessionExpiredDialogMessage;
        this.onOkFunc = this.closeModal();
        setTimeout(() => $('#messageDialog').modal('show'));
    }

    public showGetCardsErrorMessage() {
        this.messageDialogTitle = this.getCardsErrorDialogTitle;
        this.infoMessage = this.getCardsErrorDialogMessage;
        this.onOkFunc = this.goToMain();
        setTimeout(() => $('#messageDialog').modal('show'));
    }

    public showTpPendingMessage() {
        this.messageDialogTitle = this.getTpPendingDialogTitle;
        this.infoMessage = this.getCardsErrorDialogMessage;
        this.onOkFunc = this.goToMain();
        setTimeout(() => $('#messageDialog').modal('show'));
    }

    public showExtraMessageAfterLogin() {
        this.messageDialogTitle = this.localizationService.getMessage('extraMessageAfterLogin.title');
        this.infoMessage = this.localizationService.getMessage('extraMessageAfterLogin.content');
        this.onOkFunc = this.closeModal();
        setTimeout(() => $('#messageDialog').modal('show'));
    }

    private goToMain(): SomeFunc {
        return () => {
            this.router.navigate(['/main-view']);
        };
    }

    private logoutWrapper(): SomeFunc {
        return () => {
            this.logout();
        };
    }
}
