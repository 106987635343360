import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { TranslatePipe } from './pipes/translate.pipe';
import { LocalizationService } from './service/localization.service';
import { NoSupportedLocalesException } from './exceptions';

const LOCALIZATION_OPTIONS = new InjectionToken<string>('LOCALIZATION_OPTIONS');

export function localizationFactory(localizationService: LocalizationService, supportedLocales: string[]) {
    function getCookieByName(name) {
        const matches = document.cookie.match(
            new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + '=([^;]*)'),
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    if (!supportedLocales || !supportedLocales.length) {
        throw new NoSupportedLocalesException();
    }

    const cachedLocale: string = getCookieByName('currentLang');
    // @ts-ignore
    const clientLocale: string = window.navigator.language || window.navigator.userLanguage;
    const targetLocale: string = supportedLocales.find((locale) => locale === cachedLocale)
        ? cachedLocale
        : supportedLocales.find((locale) => locale === clientLocale)
            ? clientLocale
            : supportedLocales[0];
    // tslint:disable-next-line:only-arrow-functions
    return function() {
        const locale = localStorage.getItem('locale');
        localizationService.setLocale(locale || targetLocale);
        localizationService.setAvailableLanguages(supportedLocales);
    };
}

@NgModule({
    declarations: [TranslatePipe],
    exports: [TranslatePipe],
})
export class IvNgLocalizationModule {
    static forRoot(supportedLocales: string[]): ModuleWithProviders<IvNgLocalizationModule> {
        return {
            ngModule: IvNgLocalizationModule,
            providers: [
                { provide: LOCALIZATION_OPTIONS, useValue: supportedLocales },
                {
                    provide: APP_INITIALIZER,
                    useFactory: localizationFactory,
                    multi: true,
                    deps: [LocalizationService, LOCALIZATION_OPTIONS],
                },
            ],
        };
    }
}
